import React, {Component} from 'react';
import PropTypes from 'prop-types';
import { Container, Row, Col } from 'reactstrap';
import QuestionType from "./QuestionType";
import Input from "reactstrap/lib/Input";
import FormGroup from "reactstrap/lib/FormGroup";
import { FaCheck } from 'react-icons/fa';
import ReactQuill, {Quill} from "react-quill";

import { registerSmartBreak } from "./registerSmartBreak";

const Parchment = Quill.import("parchment");

registerSmartBreak();

function lineBreakMatcher() {
  var Delta = Quill.import("delta");
  var newDelta = new Delta();
  newDelta.insert({ break: "" });
  return newDelta;
}

function handleLinebreak(range, context) {
  const { quill } = this;
  const currentLeaf = quill.getLeaf(range.index)[0];
  const nextLeaf = quill.getLeaf(range.index + 1)[0];

  quill.insertEmbed(range.index, "break", true, Quill.sources.USER);

  if (nextLeaf === null || currentLeaf.parent !== nextLeaf.parent) {
    quill.insertEmbed(range.index, "break", true, Quill.sources.USER);
  }
  quill.setSelection(range.index + 1, Quill.sources.SILENT);

  Object.keys(context.format).forEach((name) => {
    if (Parchment.query(name, Parchment.Scope.BLOCK)) return;
    if (Array.isArray(context.format[name])) return;
    if (name === "link") return;
    quill.format(name, context.format[name], Quill.sources.USER);
  });
}

function handleEnter(range, context) {
  const { quill } = this;

  if (range.length > 0) {
    quill.scroll.deleteAt(range.index, range.length);
  }
  const lineFormats = Object.keys(context.format).reduce((acc, format) => {
    if (
      Parchment.query(format, Parchment.Scope.BLOCK) &&
      !Array.isArray(context.format[format])
    ) {
      acc[format] = context.format[format];
    }
    return acc;
  }, {});

  const previousChar = quill.getText(range.index - 1, 1);

  quill.insertText(range.index, "\n", lineFormats, Quill.sources.USER);

  if (
    previousChar == "" ||
    (previousChar == "\n" &&
      !(context.offset > 0 && context.prefix.length === 0))
  ) {
    quill.setSelection(range.index + 2, Quill.sources.SILENT);
  } else {
    quill.setSelection(range.index + 1, Quill.sources.SILENT);
  }
  Object.keys(context.format).forEach((name) => {
    if (lineFormats[name] != null) return;
    if (Array.isArray(context.format[name])) return;
    if (name === "link") return;
    quill.format(name, context.format[name], Quill.sources.USER);
  });
}

class QuestionWYSIWYG extends QuestionType {
  constructor(props) {
    super(props);
    this.state = {
      text: null
    };
  }

  componentDidMount() {
    if (this.state.text === null) {
      this.state.text = this.getTextValue();
      this.setState({text: this.state.text});
    }
  }

  getTextValue() {
    let txt = this.getValue();
    if (Array.isArray(txt) && txt.length > 0) {
      txt = txt[0];
    }
    if (txt == null && this.hasVorauswahl()) {
      txt = this.getVorauswahl();
    }
    return txt;
  }

  onEditorChange(text) {
    this.setState({text: text});
    clearTimeout(this.fireUpdate);
    this.fireUpdate = setTimeout(() => this.setValue(this.state.text), 1500);
  }

  render() {


    return <Container fluid={true} className={"rich-text-container "+(this.hasError() ? "error" : "")}>
      <Row>
        <Col className={"p-1 wysiwyg-editor"} md={12}>
          {this.isVorauswahl() && <FaCheck className={"vorauswahl_check"} onClick={(e) => this.onVorauswahlCheckClicked(e)}/>}
          <ReactQuill id={"quill-wysiwyg"} theme="snow"
                      placeholder={this.placeholder()}
                      value={this.state.text}
                      modules={{
                        clipboard: {
                          matchers: [["BR", lineBreakMatcher]],
                          matchVisual: false
                        },
                        keyboard: {
                          bindings: {
                            break: {
                              key: 13,
                              handler: handleEnter
                            },
                            linebreak: {
                              key: 13,
                              shiftKey: true,
                              handler: handleLinebreak
                            }
                          }
                        }
                      }}
                      readOnly={this.isFinished() || this.isDisabled()}
                      onChange={(text, delta, source, editor) => {
                          if (source == 'user') {
                            // place whatever function you want to execute when user types here:
                            this.onEditorChange(text);
                          }
                        }}
          />
          {this.renderErrors()}
        </Col>
      </Row>
    </Container>;
  }
};

QuestionWYSIWYG.propTypes = {

};


export default QuestionWYSIWYG;
